let teamData = {
  globalht: [
    {
      team_name: "IV-Safe",
      team_members: [
        "Ha Tze Han Shaun, 1004403",
        "Chew Liu Im, 1004608",
        "Pui Jia Ying, 1004638",
        "Wong Hoi Lam, 1006604",
      ],
      project_title: "Securement of IV cannula",
      project_description:
        `Insertion of intravenous (IV) cannulas into the vein is common among hospitalised patients, for administration of IV medications, fluids, or for blood sampling. Current methods to secure the IV cannula include micropore tape and tegaderm are unable to minimise the movements of the IV cannula and the drip line during daily activities. These movements may cause mechanical phlebitis, a condition in which the patient experiences redness and pain along the insertion area due to vein inflammation. A proposed securement device for the IV line is designed to minimise isolate the movement of the IV line reducing the movement at the cannula when administered near the dorsum at region. The device can be used in conjunction with existing securement techniques without interfering with the infusion process or obscuring the drip line to reduce the potential development of mechanical phlebitis among patients.`,
      healthcare_partners: ["SGH - Nanthakumahrie D/O Gunasegaran"],
      zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
      passcode: "SutdIHD@20",
    },
    {
      team_name: "Assis(tem)",
      team_members: [
        "Heather Lee Xuan Hui, 1004344",
        "Vaishnavi Divya Shridar, 1004600",
        "Suzanne-Kae Rocknathan, 1004617",
      ],
      project_title: "Home-nurse case distribution",
      project_description:
        `Assisi Home Care sees fast turnover patient rates, with new cases arriving daily. The acceptance of these cases by doctors and subsequent distribution of these new cases to nurses is a time-consuming and manual process that is undertaken everyday. To ease this process, team Assis(tem) has designed an easy-to-use and localised solution to reduce the time spent on these processes, while maintaining patient confidentiality. The solution automates summaries and presents comprehensive visualisations to facilitate an easier decision-making process for case acceptance and distribution, with minimal input required.`,
      healthcare_partners: ["Assisi - Ms Saw Nandar Swe", "Assisi - Ms Claire Ann Rayco Ricofort"],
      zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
      passcode: "SutdIHD@20",
    },
    {
      team_name: "Can eat",
      team_members: [
        "Ritta d/o Paramalingam, 1003411",
        "Lim Yi Shen, 1004338",
        "Rei-Lin Tran, 1004420",
      ],
      project_title: "Prevention of Food-drug interactions",
      project_description:
        `Food-drug interactions may decrease drug bioavailability which predisposes the patient to treatment failure or increase drug bioavailability which increases the risk of undesired and adverse side effects. While patients are usually informed on such interactions they may not be able to retain the information and resort to coming in to the hospitals just to consult clinicians. In order to make it more convenient by giving these patients ready access to such information whenever desired, our group proposes an app which allows the user to input their medication(s) and see a list of prohibited and alternative foods, as well as common local dishes that they can eat.`,
      healthcare_partners: ["SGH - Lim Shu Hui"],
      zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
      passcode: "SutdIHD@20",
    },
    {
      team_name: "Stopflo",
      team_members: [
        "Rebekah Mariam Varughese, 1004583",
        "Revathi Ravindran, 1004629",
        "Tan Yunyi, 1004645",
        "Muhammad Amirul Raziq Hj Rosman, 1006607",
      ],
      project_title: "Improving care of indwelling catheters",
      project_description:
        `An indwelling catheter (IDC) is a catheter that is inserted from the urethra to the bladder to drain urine to a bag outside the body. One potential complication of IDC is the backflow of urine from the urine bag back to the bladder, which happens if the urine bag is above the bladder and the urine flows back due to gravity, which may lead to urinary tract or kidney infections. Although patients who return home with an IDC are educated on the need to keep the urine bag below the level of the bladder, it may be challenging or inconvenient to ensure this at all times especially if the patient is mobile and is leading an active lifestyle. To reduce these catheter-associated tract infections, we from StopFlo propose a design for a one-way valve which will mitigate the backflow so these active and mobile patients will be able to keep living their lives as per their usual.`,
      healthcare_partners: ["SGH - Juriyah Bte Yatim"],
      zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
      passcode: "SutdIHD@20",
    },
  ],
  aihealthcare: [
    
      {
        team_name: "Hipnosis",
        team_members: [
          "Dharmesh (Leader), 1004368",
          "Mridula Ratheesh Thekkedath, 1003817",
        ],
        project_title: "AI Tool for Hip Fracture Detection",
        project_description:`Hip factures in the elderly is a growing problem, and the global number of hip fractures is expected to increase from 1.3 million in 1990 to 4.5 million by year 2050. Early detection of occult proximal femoral fractures will reduce morbidity and mortality in the elderly patients that sustain these injuries. The primary line of investigation to quickly diagnose hip fractures is through pelvic X-ray radiographs. However, the large number of radiographs (including other medical conditions) processed by the radiologists can result in fatigue, and may result in poor detection of occult fractures. 
        
        With the help of artificial intelligence and computer vision machine learning algorithms, we can aid to detect hip fractures efficiently and accurately. This model can help to eliminate non-fracture cases and direct the radiologists’ attention to the X-rays with a potential fracture. Thus, increasing the timeliness and accuracy of hip fracture diagnosis.`
          ,
        healthcare_partners: ["Ting Yonghan (TTSH)"],
        zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
        passcode: "SutdIHD@20",
      },
      {
        team_name: "SafeEar",
        team_members: [
          "Azeez Raasheeda Fathima, 1004493",
          "Leong Yun Qin Melody, 1004489",
          "Sahad Zahir  (Leader), 1004685",
        ],
        project_title: "AI-driven Outpatient Clinic Appointment Booking System",
        project_description:
        `The Ear-Nose-Throat (ENT) Centre attends to referrals of patients with ear conditions from various polyclinics or the hospital’s Emergency Department, which are triaged manually and dependent on the clinical experience of the referring doctor. Conditions such as Bell’s Palsy and Sensorineural Hearing Loss must be treated early for improved clinical outcomes. While the majority of conditions are accorded the appropriate level of urgency, there are instances that slip through and are not seen early enough. This results in delayed assessment, diagnosis and treatment. 
        
        In our project, we will explore deep learning and Natural Language Processing (NLP) to process the referrals and diagnosis to predict the urgency of outpatient care in the ENT clinic. This way, having a more objective means for the AI-driven outpatient appointment system will not only potentially reduce the subjective elements in the man-in-the loop appointment booking process, it will enhance patient care and improve clinical outcomes by ensuring that patients with medical conditions requiring earlier interventions are diagnosed and treated earlier, and provide administrative man-hours savings.`,
        healthcare_partners: ["Loh Chi Yuan Ian and Kwek Wei Ming James (CGH)"],
        zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
        passcode: "SutdIHD@20",
      },
      {
        team_name: "Meowringoplasty",
        team_members: [
          "Ho Xin Yi Felice, 1004605",
          "Jiang Qianfeng  (Leader), 1004648",
          "Koh Hui Juan, 1004580",
        ],
        project_title:
          "Predictive Algorithm on Improvement in Hearing Loss after Successful Myringoplasty using Machine Learning Techniques",
        project_description:
        `Perforations (holes) in the tympanic membrane (eardrum) can result in hearing loss. Such perforations can be repaired by the surgical procedure myringoplasty . However, the success of myringoplasty depends on various factors, which possibly include the time to surgery. Despite current literatures that detail the various pre-operative surgical factors that affect post-operative hearing outcomes, a consensus has yet been established. Given the wide range of factors that could influence post-myringoplasty hearing outcomes, artificial intelligence utilizing machine learning techniques could be adopted to predict post-operative outcomes and hearing improvement. 
        
        In this study, we used machine learning algorithms including decision tree regressors and neural networks to regress the post-operative pure tone average air-bone-gap based on retrospectively collected patient’s demographics and pre-operative parameters, as well as surgical factors employed. This information will be helpful for patient counselling to help them make a more informed decision whether to proceed with myringoplasty especially when the indication for myringoplasty is ambiguous.`,
        healthcare_partners: [
          "Hazel Yeo, Kenneth Chua, Savitha Kamath and Yuen Heng Wai (CGH)",
        ],
        zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
        passcode: "SutdIHD@20",
      },
      {
        team_name: "APTBERT",
        team_members: [
          "Terence Tan Wei Jie, 1004111",
          "Lim Hao, 1004648",
          "Yu Nicole Frances Cabansay  (Leader), 1004574",
        ],
        project_title:
          "Development of an AI Tool combining Emergency Department (ED) triage presentation, vitals and NEWS scores to predict ED patients’ acuity and final disposition outcome ",
        project_description:
        `The Emergency Department (ED) experiences continuous streams of patients throughout the day, and may sometimes experience higher than usual caseloads in a fast-paced resource-constrained environment. While all patients are tended to, some patients need more urgent care depending on the presenting symptoms. ED doctors and nurses evaluate the severity of the case and urgency of care for each patient based on a combination of Patient Acuity Category Score, Emergency Severity Index, and triage complaints. Such current methods of triaging and assigning patients to appropriate care zones in the hospital’s Emergency Department is rather manual, and at times subjective, which may result in disparities in allocating the patient to the appropriate care zone with the needed level of care, and imbalanced number of patients in the different care zones. These could affect the patients’ well-being and place a significant strain on the hospital’s resources, given the limited manpower and inpatient beds.
        
        In our proposed solution, we used Natural Language Processing (NLP) techniques to analyse the triage complaints and presenting symptoms recorded by the ED nurses in order to predict the patients’ acuity and final disposition outcome with optimal clinical accuracy. Hence, developing such advanced AI triage model which takes into consideration the patient’s vitals and severity index with triage complaints can help to accurately assign the needed level of care afforded to the patient, optimize hospital resources, reduce need for inappropriate admissions, and balance the caseloads in different care zones, thus ameliorating longer wait times and reducing staff burnout. `,
        healthcare_partners: ["Annitha Annathurai (SKH)"],
        zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
        passcode: "SutdIHD@20",
      },
    
  ],

  healthcarepd: [
    // {
    //   team_name: "G02 - Pharyngeal Imaging Forceps",
    //   team_members: [
    //     "Chew Liu Im, 1004608",
    //     "Ha Tze Han Shaun, 1004403",
    //     "Nicole Ng Sok Teng (Huang Xue Ting), 1004488",
    //     "Sumi Boo, 1003594",
    //     "Timothy Lim Yee Da, 1004302",
    //   ],
    //   project_title: "Pharyngeal Imaging Forceps",
    //   project_description:
    //     "The existing methods of visualising the mouth and throat (pharynx) are uncomfortable for a proportion of patients and \nrequires a certain level of skill on the part of the operator. This is due to the complex anatomy of the pharynx which requires \nvarying degrees of angulation to access the throat depending on the patient’s anatomy. Foreign bodies are also typically found in \nthe tonsil, valleculae and tongue base. Visualisation is currently performed either through a nasoendoscope or direct visualisation \nusing a mirror. In the former, the procedure entails passing a flexible scope through the nose, winding down the back of the nose \nand mouth before being able to see the throat. In the latter, the laryngeal mirror is positioned in the mouth and one sees the \nreflection of throat. This is often challenging and is dependent on the anatomy and gag reflex of the patient. Additionally, retrieval \nof foreign objects requires coordination of the mirror/scope in one hand, and a retrieval forceps in the other.\nThe project aims to design a multi use device that can be operated with one hand to visualise the mouth and the throat as well as retrieve \nforeign objects through the mouth will, in a subset of patients with suitable anatomy, improve the patient experience and safety as \nwell as provide a much faster and safer option of visualising and retrieving objects in the larynx and pharynx.",
    //   healthcare_partners: ["Changi General Hospital (CGH)"],
    //   zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
    //   passcode: "SutdIHD@20",
    // },
    {
      team_name: "Cement Injector Device",
      team_members: [
        "Heng Yee Ying Beverly, 1004621",
        "Lucas Tan Zhong Hui, 1004616",
        "Ng Yong Han Jonah, 1004291",
        "Tan Jean Yee, 1004317",
        "Yeo Shu Lin, 1004400",
      ],
      project_title:
        "Improved Cement Injector Device for Vertebroplasty and Kyphoplasty Procedures for Osteoporotic Vertebral Compression Fractures",
      project_description:
        "Osteoporotic vertebral compression fractures are common in the elderly population and associated with significant morbidity and mortality, its incidence is projected to rise with aging population in Singapore. There were 4444 osteoporotic vertebral fractures in 2017, with an expected 58% increase to 7020 fractures in 2035.\nThis project aims to design a safe cement injector device for improved cement fill of vertebral bodies during vertebroplasty and kyphoplasty procedures for osteoporotic vertebral fractures. ",
      healthcare_partners: ["Changi General Hospital (CGH)"],
      zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
      passcode: "SutdIHD@20",
    },
    // {
    //   team_name: "Patient-Specific Cutting Jig",
    //   team_members: [
    //     "Josiah Tan Jia Ern, 1004121",
    //     "Ko Jun Hsien Calvin, 1004262",
    //     "Ong Zhi Xiang, 1004268",
    //     "Phoebe Leam Xin Ni, 1004451",
    //     "Wong Hoi Lam, 1006604",
    //   ],
    //   project_title:
    //     "Patient-specific cutting jig with tissue protection sleeve for high tibial osteotomy",
    //   project_description:
    //     "High tibial osteotomy is an excellent option for young patients with osteoarthritis as it restores mechanical alignment of the lower limb in a bid to reduce the mechanical overload over the affected compartment of the knee. This is believed to slow down the progression of osteoarthritis and avoid patients having to undergo total knee replacement, which has inferior outcomes in younger populations. However, the utilization rate of high tibial osteotomy remains low. This is due to perceived complexity of the surgery, learning curve as well as complications of hinge fracture and injury to neurovascular structures at the posterior aspect of the knee. This project aims to design a device which will allow accurate saw cuts to be made based on pre-operative planning of osteotomy as well as provide soft tissue protection for the posterior neurovascular bundle of the knee.",
    //   healthcare_partners: ["Sengkang General Hospital (SKH)"],
    //   zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
    //   passcode: "SutdIHD@20",
    // },
    {
      team_name: "Proning device for Patient Transfer",
      team_members: [
        "Amelia Natasha Yeo Wen Ting, 1004342",
        "Chong En Xin Charis, 1004592",
        "Lim Jin Feng, Nick, 1004130",
        "Pui Jia Ying, 1004638",
        "Rebekah Mariam Varughese, 1004583",
      ],
      project_title:
        "Proning device for transfer of patient in Operating Theatre",
      project_description:
        "There are many surgeries that require the patient to be in the prone position for access. These commonly include orthopaedic surgeries on the throacolumbar spine, ankle, urology, colorectal surgery endoscopy etc. Patients are anaesthetized first in the supine position for purpose of securing the airway with a breathing tube. Subsequently, the anaesthetized patient is then turned prone manually, with the aid of about five to eight (depending on the size of the patient) healthcare workers. Manual patient handling increases the risk of work related injuries amongst healthcare workers e.g. low back pain and musculoskeletal injuries.\nIt can also increase patient injury as the anaesthetized patients are dependent on staff to bear their weight and protect vulnerable areas during positioning.\nThis project aims to design a portable hoist device to assist in lifting and turning the patient from supine to prone safely, with minimal manpower to avoid occupational injury for staff and patient protection. ",
      healthcare_partners: ["Sengkang General Hospital (SKH)"],
      zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
      passcode: "SutdIHD@20",
    },
    {
      team_name: "Ambulatory Uroflowmetry Device",
      team_members: [
        "Brendan Ong Yen Kie, 1004099",
        "Keane Hi Zhe Wei, 1004149",
        "Sahad Zahir, 1004685",
        "Teo Wei En Glenn, 1004105",
        "Wong Wen Kang, 1004232",
      ],
      project_title: "A self-administered ambulatory uroflowmetry device",
      project_description:
        'Lower urinary tract symptoms (LUTS) including slow urinary flow secondary to benign prostate hypertrophy (BPH) is a very common problem that prompts men to seek medical help; these men form the majority of patients in a busy urology clinic. A uroflowmetry device enables one to accurately and objectively assess a patient’s urinary flow rate. It is an intricate and irreplaceable tool in any urologist’s practice. Patients undergo the uroflowmetry assessment in the clinic, but often feel time-pressured in an unfamiliar environment, resulting in inaccurate results, similar to the concept of "white-coat hypertension".\nThis project aims to design an ambulatory uroflowmetry device that is cost-effective and easy to self-administer is not available at present.',
      healthcare_partners: ["Sengkang General Hospital (SKH)"],
      zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
      passcode: "SutdIHD@20",
    },
    {
      team_name: "NG Tube Placement",
      team_members: [
        "Diya Bhujanga Kotian, 1004699",
        "Liew Iris, 1004409",
        "Poh Wen Bin, 1004264",
        "Ramachandran Murali Krishnan, 1005766",
        "Xing Yunqing, 1004527",
      ],
      project_title: "Alternative Nasogastric Tube Placement Check",
      project_description:
        "Enteral nutrition via nasogastric tube (NGT) is common in the community setting, and even more so in long term care facilities. Change of NGT is required every 1-2 months, depending on type of NGT used. More frequent change may also be required when NGT gets blocked by food residue. While chest radiography (CXR) remains the gold standard of confirming appropriate placement of NGT, it often not feasible or cost effective to do so in the community setting. Hence, a device which can assist in confirming NGT placement would be of great utility.",
      healthcare_partners: ["Sengkang General Hospital (SKH)"],
      zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
      passcode: "SutdIHD@20",
    },
    {
      team_name: "Patient Positioning Device",
      team_members: [
        "Claudia Koh Wei Ting, 1004679",
        "Kek Jing Wen, 1004476",
        "Mun Jern Wei Ivan, 1004107",
        "Revathi Ravindran, 1004629",
        "Tan Shin Yee, 1004458",
      ],
      project_title:
        "Patient Positioning Device (A Device that helps Moves Patients Upwards in Bed)",
      project_description:
        "Bed-bound patients are normally nursed with the head of bed slightly elevated to promote comfort and reduce risk of lung infection. However, patients tend to slide downwards towards the foot of the bed, ending up in a “slouched” position. More than one nurse will then be needed to pull the patient upwards. This act can be uncomfortable for patients and nurses may sustain injuries. Time is also needed to gather more nurse. Hence, we aim to design a device that can automate the movement of patients upwards in bed with the push of a button.",
      healthcare_partners: ["Changi General Hospital (CGH)"],
      zoom_link: "https://sutd-edu.zoom.us/j/94538413956",
      passcode: "SutdIHD@20",
    },
  ],
};

// let teamData2 = [
//   {
//     aihealthcare: [
//       {
//         team_1: {
//           team_name: "KUB-bers",
//           zoom_link: "https://sutd-edu.zoom.us/j/91823349791",
//           passcode: "AI@Kub21",
//           project_title:
//             "Use of AI, Machine learning and Deep Learning in Detecting Urinary Tract Stones On KUB X-Ray",

//           project_description: `Currently, x-ray are unable to detect Kidney and bladder stones well due as it is a 2D imaging tool. More accurate diagnosis requires the usage of 3D CT imaging, but it has the drawbacks of long wait time as well as exposing the patient to high dosage of radiation

//             Our solution looks to develop an AI model using Computer Vision techniques, that is trained to detect visible kidney and bladder stones on the 2D x-ray scans.

//             Our solution serves as the first step in trying to solve a more complicated problem of being able to detect the kidney and bladder stones on the 2D x-ray image, even if they are not visible.`,

//           team_members: [
//             "Cornelius Yap*, 1003358",
//             "Abu Bakar Bin Othman, 1003856",
//             "Melissa, 1003850",
//           ],

//           healthcare_partners: [
//             "Dr Terrence Lim (CGH)",
//             "Dr Teo Xin Ling (CGH)",
//           ],
//         },
//       },
//       {
//         team_2: {
//           team_name: "LA Detectives",
//           zoom_link: "https://sutd-edu.zoom.us/j/94915167930",
//           passcode: "AI@LAd21",

//           project_title:
//             "Hybrid Unsupervised or Supervised Approach to Content-Based Image Retrieval of Look-Alike Medications",

//           project_description: `Currently, pharmacists have to rely on their memory to avoid procuring look-alike (LA) drugs in order to prevent downstream errors during dispensement by doctors and consumption by patients. Our project seeks to reduce such confusion through the implementation of a content-based image retrieval system. It is a trained Siamese network which will return similar-looking drugs, given an input image.

//             In order to perform supervised learning well, we needed a way to efficiently generate sufficient labeled data. To clean our dataset, we first trained a Yolov5 model to automatically detect and crop our own custom object of interest. For this project, we are only focusing on strip medications. To generate potential LA candidates based on the cropped dataset, we compared correlation values of RGB histograms as well as performed k-means clustering on the deep embeddings of our pre-trained autoencoder. Human annotators are subsequently recruited to filter out dissimilar-looking images based on a set of guidelines. Another round of verification is done by the pharmacist, which forms our final labeled dataset for the one-shot learning task.

//             Finally, the trained Siamese network is evaluated using its accuracy on the N way one shot learning tasks as well as its mean Average Precision Score. Its performance will also be compared with the pharmacists.`,

//           team_members: [
//             "Loh De Rong*, 1003557",
//             "Ng Zhen Hao, 1003902",
//             "Gan Hui Ting, 1003453",
//           ],

//           healthcare_partners: ["Sheila Ling (CGH)", "Christina Ng (CGH)"],
//         },
//       },
//       {
//         team_3: {
//           team_name: "Inventory-Assist",
//           zoom_link: "https://sutd-edu.zoom.us/j/96113560302",
//           passcode: "AI@INv21",

//           project_title:
//             "Inventory Identification through Deep Learning Image Recognition",

//           project_description: `During hospital implant operations, nurses are required to manually key down the specific implants that were used in the Electronic Medical Record so that they can be individually identified for their granular monitoring and tracking. This process is time and labour intensive and prone to human error.

//             Through this project, our group aims to use AI driven image recognition technology to swiftly detect the content of the implant boxes and correctly identify relevant content for the correct fields.
//             `,

//           team_members: ["Lee Hang Wee*, 1002727", "Mong Chang Hsi, 1003085"],

//           healthcare_partners: [
//             "Dr Goh Han Leong (IHIS)",
//             "Zhuang Yiwen (IHIS)",
//             "Jenny Zou Lin (IHIS)",
//             "Tan Hsiu Li (IHIS)",
//           ],
//         },
//       },
//       {
//         team_4: {
//           team_name: "Hip-hop",
//           zoom_link: "https://sutd-edu.zoom.us/j/98804231662",
//           passcode: "AI@HIp21",
//           project_title: "AI Tool for Occult Hip Fracture Detection",

//           project_description: `Hip fractures are a leading cause of death and disability among older adults. Hip fractures are also the most commonly missed diagnosis on pelvic radiographs, and delayed diagnosis leads to higher cost and worse outcomes.

//             The current process of identifying potential fractures is by inspecting the X-Ray, gauging a rough idea of the situation and sending the patient for further scans. We aim to remove this middle step of inspecting the X-Ray by automating the process using Computer Vision and Deep Learning.

//             The goal of our model is to predict whether the X-Ray looks completely normal or whether there is a potential fracture, in which case further scans should be done.
//             `,

//           team_members: [
//             "Varsha Venkatesh*, 1003646",
//             "Rahul Parthasarathy, 1003718",
//             "Huang Zhibo, 1003558",
//           ],

//           healthcare_partners: ["Dr Ting (TTSH)", "Chen Wen Xiang (TTSH)"],
//         },
//       },
//     ],

//     globalht: [
//       {
//         team_1: {
//           team_name: "ARTability",
//           zoom_link: "https://sutd-edu.zoom.us/j/95017898334",
//           passcode: "GHT@Art21",
//           project_title: "Multi-Purpose Surface for Art Therapy",

//           project_description: `Art Therapy allows pallative care patients to express their feelings through creative handiwork. However, mobility issues restrict some patients from fully reaping the benefits of the therapy sessions. We designed a surface that empowers patients to take part in these session.`,

//           team_members: [
//             "Vijay Kevin Solomon*, 1003295",
//             "Abu Bakar Bin Othman, 1003856",
//             "Nicole Sim Fang Sing, 1003064",
//             "Sylvia Soh Tsin Hyui, 1002842",
//           ],
//           healthcare_partners: ["Calvin Pang", "Vivian Wong"],
//         },
//       },
//       {
//         team_2: {
//           team_name: "C(All)Bell",
//           student_id: [],
//           email_address: [],
//           zoom_link: "https://sutd-edu.zoom.us/j/91060089133",
//           passcode: "GHT@CaB21",
//           project_title: "Easy Access Call Bell",

//           project_description: `Designing an attachment to the current call bell in use that allows patients lacking fine motor skills to easily press the bell for assistance.`,

//           team_members: [
//             "Sithanathan Bhuvaneswari*, 1003798",
//             "Jiang ChenXi, 1003782",
//             "Muhammad Shahul Hameed Bin Subari, 1003372",
//             "Nga Jing Ting, 1003787",
//           ],

//           healthcare_partners: ["Celine Yong"],
//         },
//       },
//       {
//         team_3: {
//           team_name: "Free Drink",
//           zoom_link: "https://sutd-edu.zoom.us/j/94011161080",
//           passcode: "GHTFD01@",
//           project_title: "Variable Volume Limiting Straw",

//           project_description: `One of the biggest issues for dysphgaia patients is the safe consumption of fluids which can cause choking and other danergous consequences. We have designed a product that ensures a safe level of volume intake while granting patient autonomy. `,

//           team_members: [
//             "Cheong Rui Zhi Jeremy*, 1002763",
//             "Ng Pei Shi, Doreen, 1003837",
//             "Declan Ng Kai Rei, 1003314",
//             "Chai Chi Yee, Alysia, 1003818",
//           ],
//           healthcare_partners: ["Gwendoline Soh", "Michelle Boo"],
//         },
//       },
//       {
//         team_4: {
//           team_name: "FentTrack",
//           zoom_link: "https://sutd-edu.zoom.us/j/98869719608",
//           passcode: "GHTCP02#",
//           project_title: "Fentanyl Patch Management System",

//           project_description: `Delay or failure in removal of due Fentanyl patch may lead to detrimental overdose effects. Our team aims to aid nurses in the tracking of the patches' due date and their administered locations for every patient.`,

//           team_members: [
//             "Gan Yu*, 1003586",
//             "Clarice Chua Qing Yu, 1003837",
//             "Glenda Wee Qihui, 1003314",
//             "Cheah Siew Ru, 1003818",
//           ],
//           healthcare_partners: ["Chiew Cheng Fong"],
//         },
//       },
//     ],

//     healthcarepd: [
//       {
//         team_1: {
//           team_name: "Expiratory Muscle Trainer",
//           zoom_link: "https://sutd-edu.zoom.us/j/95747716980",
//           passcode: "JHE#2021",
//           project_title:
//             "EMT3D- Expiratory Muscle Trainer for Parkinson's Disease",

//           project_description: `EMT3D is the first 3D-printed Expiratory Muscle Trainer (EMT) that seeks to cater to patients with Parkinson Disease (PD) by solving the gaps in current EMT devices and providing a cost-effective treatment. In the long run, EMT3D aims to help them improve their airflow, cough function and swallowing ability to perserve functionality and maintain their quality of life.`,

//           team_members: [
//             "Enoch Chong*, 1003327",
//             "Brandon Yeo Yun Zhang, 1003380",
//             "Nicholas Siew Jiming, 1003491",
//             "Natasha Koh, 1003635",
//             "Mok Rong Shan, 1003844",
//           ],

//           healthcare_partners: ["Ms Wee Xuefeng", "Ms Lee Yan Qing"],
//         },
//       },
//       {
//         team_2: {
//           team_name: "Plushy Lifter",
//           zoom_link: "https://sutd-edu.zoom.us/j/99015809584",
//           passcode: "JHE#2021",

//           project_title: "Plushy Lifter",

//           project_description: `Plushy Lifter is a leg elevation device that serves to reduce a clinician's physical strain that goes into performing a manual leg raise procedure and the clinician's mental effort that goes into ensuring the leg positions are properly maintained. This adaptable device standardizes the leg raise procedure and saves time and effort of the clinicians in the A&E and in other parts of the hospital.`,

//           team_members: [
//             "Kok Yu Hung Patrick*, 1002702",
//             "Sylvia Soh Tsin Hyui, 1002842",
//             "Hong Li-En Nyssa, 1003794",
//             "Sithanathan Bhuvaneswari, 1003798 ",
//             "Natalie Lim Miin, 1003854",
//           ],

//           healthcare_partners: ["Dr Lim Hoon Chin"],
//         },
//       },
//       {
//         team_3: {
//           team_name: "Unihaler",
//           zoom_link: "https://sutd-edu.zoom.us/j/96959039873 ",
//           passcode: "HPD@Uni21",
//           project_title: "Universal Inhaler Dose Monitoring Device",

//           project_description: `Clinicians overseeing asthmatic patients are unable to accurately assess their adherence to their medication and therefore, prescribe the most suitable medical treatment plan. Our solution, Unihaler, is a universal, cost effective and secure solution for clinicians to tackle this problem.`,

//           team_members: [
//             "Darrell Chia Yun Leng*, 1002683",
//             "Ong Wei Song, 1003310",
//             "Declan Ng Kai Rei, 1003314",
//             "Siti Nurbaya, 1003524",
//             "Abu Bakaar Bin Othman, 1003856",
//           ],

//           healthcare_partners: ["Dr Tay Tunn Ren"],
//         },
//       },
//       {
//         team_4: {
//           team_name: "MuG(Multiple Needle Guide)",
//           zoom_link: "https://sutd-edu.zoom.us/j/97701448938",
//           passcode: "HPD@MuG21",
//           project_title: "Simultaneous Multiple Needle Guide (SMNG)",

//           project_description: `Percutaneous tumour ablation requires accurate placement of ablation needles into target tumour under image guidance. Free hand insertion of needles one at a time is time consuming as it often requires several attempts to ensure satisfactorily parallel trajectories. We aim to design a simple guiding device, external to the body, that permits the parallel passage of 2-4 needles once the first needle is placed.`,

//           team_members: [
//             "Muhammad Shahmi bin Mohd Sabli*, 1001117",
//             "Chan Jie Lin, 1003316",
//             "Bryant Tay Yu Qin, 1003492",
//             "Dennis Jordan Madhavan, 1003524",
//             "Nga Jing Ting, 1003856",
//           ],

//           healthcare_partners: ["Dr. Shi Haiyuan(Interventional Radiologist)"],
//         },
//       },
//       {
//         team_5: {
//           team_name: "Wearable Heating Modality",
//           zoom_link:
//             "https://sutd-edu-sg.zoom.us/j/93021856269?pwd=N2FqN3ltYkZveUFKN2QydUJLT3FWdz09",
//           passcode: "038778",
//           project_title: "Superficial Heat Modality (eSiHeat)",

//           project_description: `The purpose of our device, a superficial heating modality, is to ensure and maintain a source of sustainable heat for an ample amount of space across the upper limb of post-trauma patients. By using thermotherapy, we aim to alleviate the patient’s pain, reduce stiffness and enhance their range of motion (ROM) which would improve their clinical outcome.

//             We aim for our device to serve as a suitable alternative for current treatment modalities which are bulkier and require the patient to be in an uncomfortable seating position. Our method fundamentally consists of a resistance heating process in which a nichrome wire is used to convert electric to heat energy across a silicone layer which, along with an aluminium layer, ensures proper heat transmission and dissipation across the targeted area to be healed.`,

//           team_members: [
//             "Kang Yi Chao Ferrell*, 1003108",
//             "Jonathan Her Wei Quan, 1003332",
//             "M Souganttika, 1005747",
//             "Arturo Castillo Ugalde, 1005837",
//             "Arunraj S/O Chidambaram, 1005755",
//           ],

//           healthcare_partners: ["Dr Christine Goh"],
//         },
//       },
//     ],

//     healthcomm: [
//       {
//         team_1: {
//           // team_name: "blossom, BUBBLES, butterCUP",
//           team_name: "",

//           project_title: "Guilt-free Pleasures",
//           project_description: `Diabetes is a prevalent issue in Singapore. Over 440,000 Singapore residents above the age of 18 were diagnosed with diabetes in 2014, and this number was estimated to grow to 1 million by 2050. The over consumption of sugar is a key contributing factor to the development of diabetes.

//             In particular, there is a persisting trend of drinking bubble tea among Singaporean youths. Many drinks offered at bubble tea shops exceed the recommend 45g of daily sugar intake. However, the lack of awareness on a drink’s sugar content, and the healthy amount of sugar intake leads youths to frequently overconsume sugar.

//             “Guilt-free pleasures” is a collectible series of cup holders designed to visually educate its users on the sugar content of popular drinks, as compared to the recommended daily sugar intake. Each unique cup holder design caters to a specific series of drinks. The collection displayed composes of the Gong Cha Milk Tea Series and Creative Fruit Tea Mix, however this can be tailored to the bubble tea shop that the product is partnered with. The cup holder is given as a free gift when customers purchase the lowest sugar drink in the series, thus encouraging them to choose drinks that have lower sugar content, instead of their usual order. The aesthetic designs portray the sugar content information in a visual form, which serves to help users internalize the information more easily. Furthermore, by marketing the cup holders as collectibles, consumers would be more motivated to access the information presented and educate themselves on how to make healthy drink choices.`,
//           team_members: [
//             "Sesilia Fenina Gunawan, 1002702",
//             "Jessica Davinia, 1003675",
//             "Chan Yan Zhi, 1004311",
//             "Suzanne-Kae Rocknathan, 1004617",
//             "Heng Yee Ying Beverly, 1004621",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_2: {
//           // team_name: "Health Soup",
//           team_name: "",

//           project_title: "Kick the sedentary life!",
//           project_description: `Kick the sedentary life! is a campaign to reduce sedentary habits among Singaporean tweens aged 7-13 years.

//             Using game filters on Tiktok, players go through a series of story challenges where they perform physical actions to help their on-screen character, Mario, continue on his adventure and rescue Princess Peach. Players can punch, jump and kick their way to success!

//             The initial challenges are designed to be accessible even to tweens without existing habits of exercising, while later challenges of incremental difficulty encourage players to progress to higher levels of fitness whilst still providing an experience of mastery.

//             This helps tweens recognize that exercising can be fun, while inculcating the habit of exercising in their daily lives.`,
//           team_members: [
//             "Koh Yu Hung Patrick, 1002702",
//             "Lee En Qi, Amanda, 1003004",
//             "Seet Yu Lian, 1002650",
//             "Soh Jun Hern, 1002707",
//             "Tan Hui Min Grace, 1003001",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_3: {
//           // team_name: "GOYA",
//           team_name: "",

//           zoom_link: "",
//           project_title: "GOYA",
//           project_description: `GOYA (Get Off Your Ass) is a mobile application that seeks to tackle the sedentary lifestyle that many Singaporeans have adopted in the fight against the COVID-19 pandemic through gamifying an active lifestyle.

//             With GOYA, we get players to create an online persona that mirrors their person, participate in simple yet exciting collaborative fitness challenges, and share these experiences in-app with their friends, family and GOYA community.

//             Through these app features, we hope to enable Singaporeans to shift towards a non-sedentary lifestyle even during the COVID-19 pandemic and under post circuit breaker circumstances.`,
//           team_members: [
//             "Chery Lui Run Ying, 1003888",
//             "Chloe Tan, 1003859",
//             "Matthew John Phua Zhen Wei, 1004128",
//             "Ong Lok Hen, 1004647",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_4: {
//           // team_name: "healthy hAss",
//           team_name: "",

//           zoom_link: "",
//           project_title: "Sustainable Steps Challenge",
//           project_description: `Current interventions such as the National Steps Challenge have shown improvements in increasing the total physical activity hours among Singaporeans but with varied results, most notably a lower effect among the middle aged (40-49 year olds).

//             Additionally it has an overreliance on extrinsic motivators to convince exercise which can have diminished or even adverse effects in getting people to exercise.

//             Our group aims to utilize behavioural social theories to redesign both the Healthy365 app and the NCS to include a higher degree of intrinsic motivation models to complement the existing design in order to ensure that people develop longstanding exercise habits that will last throughout their lives.`,
//           team_members: [
//             "Shaun Ha, 1004403",
//             "Leong Yu Fong, 1003659",
//             "Wong Ye Qi Daryll, 1003383",
//             "Azreena Zamree, 1003425",
//             "Adhi Narayan Tharun, 1003379",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_5: {
//           // team_name: "Team 4",
//           team_name: "",

//           zoom_link: "",
//           project_title:
//             "SE:ARCH - Singapore Explore: Augmented Reality Challenge",
//           project_description: `There has been a steady increase in obesity rates of Singaporean youths over the past few years, of which one of the main causes is the lack of physical activity. A World Heath Organisation (WHO) Report in 2019 showed that approximately 75% of youths in Singapore are not physically active in their lifestyles.

//             SE:ARCH is a mobile game application that leverages on Augmented Reality (AR) to encourage youths to get up and out, to search for and explore Singapore's various landmarks, complete trails and exercise challenges while earning points. These points can be exchanged for vouchers and discounts with selected shops, such as sports equipment outlets and places with healthier food options. By gamifying exercise, we aim to make the process of exercise a more fun and engaging activity for youths to do.

//             Users can complete daily challenges and goals given by the app to earn a basic amount of points (plus a bonus if they complete all the daily challenges), and organise trails and exercise activities with family and friends to earn more points. In addition, one can even suggest new locations to search and explore for landmarks and healthy food places and jio your family and friends along for trips around SG.`,
//           team_members: [
//             "Brandon Chong, 1004104",
//             "Karyl Kang, 1003852",
//             "Tan Yan Siew, 1004665",
//             "Timothy Ng, 1003605",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_6: {
//           // team_name: "healthy healthy",
//           team_name: "",

//           zoom_link: "",
//           project_title: "Give your ASS a Break",
//           project_description: `Sedentary life has long been an issue for Singaporeans and with COVID-19 outbreak, working from home has seen longer sitting hours for more people.

//             Research has shown that long sitting hours can increase risk to all-mortality death causes regardless of gender, age or even level of physical activity. Reducing sitting hours from 8 hours per day to 4 hours per day can have the same effect as increasing physical activity level from 0 to 150 minutes per week.

//             Our project aims to target the working population, more specifically office workers who experience the highest sitting hours. We aim to reduce sitting hours by tapping on a time management pomodoro widget that allows office workers to utilize work breaks to not just stay seated and perpetuate a sedentary behavior, but instead utilize the time standing up and doing simple activities. The widget makes use of an in game avatar, enabling users to identify and share goals with the character.`,
//           team_members: [
//             "Eugene Lian Qi Xiang, 1003788",
//             "Hanny Ignasia, 1003542",
//             "Er Ding Xuan, 1003455",
//             "Dino Tan, 1003368",
//             "James Gan, 1003330",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_7: {
//           // team_name: "Balanced People",
//           team_name: "",

//           zoom_link: "",
//           project_title: "You Got Balance?",
//           project_description: `Based on a 2019 study by an access technology company Kisi, Singapore ranked 32nd out of 40 cities for work-life balance and 2nd for overworked cities in the ranking. Our team aims to reduce the occurrence rate of burn out and work-related stress caused by work life imbalance through increasing awareness of work-life imbalance signals, symptoms, and what to do. The campaign, “You got Balance?” primarily targets young working adults aged 20-34, as well as their managers.

//             "You Got Balance?" jointly presented by HPB and MOM is set out to change the injunctive norms surrounding work-life balance. A series of video and posters will feature 4 distinct characters whom exemplifies commonly exhibit symptoms and causes of work-life imbalance. They introduce tips and actionable steps to improve work-life balance.

//             To help normalise work-life separation, the campaign will distribute a print-out that will allow employees and managers to pen down their expected knock-off timings, and what they plan to do after work.`,
//           team_members: [
//             "Chan Luo Qi, 1002983",
//             "Tan Yue Han John, 1002647",
//             "Kwok Jing Ting Bernice, 1003839",
//             "Wee Jian Hui Kevin, 1002765",
//             "Daniel Tan Jun Hao, 1004375",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_8: {
//           // team_name: "WFitH",
//           team_name: "",

//           zoom_link: "",
//           project_title: "WFitH",
//           project_description: `Office workers tend to remain physically inactive during the day. The COVID-19 pandemic has caused many companies to implement Work from Home (WFH) measures; this has exacerbated the prevalence of a sedentary lifestyle among office workers. Many still WFH despite the recent easing of WFH measures. A sedentary lifestyle can lead to numerous health problems such as obesity, increased blood pressure and chronic pain.

//             Our group decided to alleviate this problem with the help of a gamified fitness mobile application, WFitH. The app determines the users’ current fitness level based on a series of questions. It then customises a suitable plan based on their current fitness level, suggesting various forms of exercise for them.

//             The app features individual and group challenges that reward users with badges and incentives. Group challenges include collaborative and competitive challenges, users can also join these challenges with their family and friends.`,
//           team_members: [
//             "Tan Jin Yuan, 1003291",
//             "Ong Xin Tong, 1003870",
//             "Wang Ze Fan, 1003791",
//             "Lai Jian Ting, 1003441",
//             "Sean Tay, 1003340",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_9: {
//           // team_name: "Health United",
//           team_name: "",

//           zoom_link: "",
//           project_title: "U ok?",
//           project_description: `Given how the current adverse circumstances faced by Singaporeans due to Covid-19 has led to a rise in mental health issues emerging from increased loneliness and isolation, our team has designed the U ok? campaign, which aims to reduce the feeling of isolation in the general public by targeting fellow young adults as our primary target group and empowering them to support their family and friends in need, who will be our secondary and final target group. The U ok? campaign consist of this poster, a promotional video and a campaign product, the U ok? mask.

//             Our project has several objectives. Firstly, we would like to significantly increase the awareness of loneliness as an existing problem within 2 weeks of campaign launch. Secondly, we would also like to significantly increase the knowledge on how to approach, acknowledge and interact with people who are potentially experiencing loneliness within 3 weeks of campaign launch. Lastly, we would like to increase the percentage of people who experience meaningful interactions between their social circles, from 54% (in August 2020) to at least 75%, within 2 months of campaign launch.`,
//           team_members: [
//             "Dev Bahl, 1003375",
//             "Zenn Png, 1003321",
//             "Jordan Tay, 1001121",
//             "Joel Lim, 1003504",
//             "Chia Hou-An, 1001583",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_10: {
//           // team_name: "WoW",
//           team_name: "",

//           zoom_link: "",
//           project_title: "World of Walkraft",
//           project_description: `Given the current onset of Covid-19 in Singapore, mobile games have become a more common way to stay connected with friends and have an enjoyable time. However, sitting on your bum the entire day is bound to do one no good.

//             With our new AR mobile game, World of Walkraft, you can either defeat monsters solo when you are on the way to your destination, or meet up with friends to conquer and defeat boss monsters! Level up to power your avatar while competing with your friends and other teams. Participate in seasonal events to unlock exclusive seasonal items. So what are you waiting for? Start walking and rise to be the top conqueror!`,
//           team_members: [
//             "Declan Ng, 1003314",
//             "Ong Wei Song, 1003310",
//             "Ng Ruoqi, 1003819",
//             "Mok Rong Shan, 1003844",
//             "Chung Wei Lin, 1004666",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_11: {
//           // team_name: "Health Pyramid 2.0",
//           team_name: "",

//           zoom_link: "",
//           project_title: "Embrace The Pyramid Scheme",
//           project_description: `With the increased prevalence of obesity among children, Health Pyramid 2.0 can be integrated in school canteens as a recommended reference for the optimal healthy serving size. Each stall vendor will be responsible for creating the perfect healthy pyramid by following a guideline provided by the Health Promotion Board (HPB), making this a multi-way approach of promoting a more nutritional lifestyle through educating these canteen stall vendors and students alike.

//             The Health Pyramid aims to not only inform students to consume food in the lower tier of the pyramid but also to give them an informed decision of what is inside their food. By interacting with the Pyramid (acting as a menu ordering system), students have to view the food layer categories before making their purchases.`,
//           team_members: [
//             "Yeo Ying Xuan, 1003835",
//             "Koh Xian Ming, 1003448",
//             "Phang Teng Fone, 1003296",
//             "Melissa, 1003850",
//             "Jazreel Kwek, 1003830",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_12: {
//           // team_name: "FitTubers",
//           team_name: "",

//           zoom_link: "",
//           project_title: "FitTube",
//           project_description: `Multiple studies (OECD, 2017; Ofcom, 2019) show that within the age of 5-7 years, more than 42% of children in United Kingdom have access to a touchscreen device and watch programs online particularly on YouTube. Apart from the various risks of digital activities like cyberbullying, harmful content to name a few, there are dangers of excessive use to physical health and well being of the child.

//             Currently YouTube has introduced functions that enable the parent to control and enable search function, the type of programs and screen time (Google Support, 2021). These functions have limitations in achieving effective goals to avoid sedentary behavior. Guidelines set by a number of countries recommend no more than 1 hour of sedentary behaviour at any given time and less than 2 hours of screen time in a day (Jones and Okely, 2020).

//             To tackle this challenge, our health intervention initiative will provide YouTube for Kids with a "FitTube" assistive function which is an interactive interstitial program that runs at timestamped chapters to prevent children between the age of 5-7 from sedentary behaviour. The interactive function will provide kids with engaging activities to break screen time durations to reduce eye strain, increase brain stimulation, language development and gross motor skills.`,
//           team_members: [
//             "Mohamed Akbar Makani, 1005705",
//             "Chai Jia Ling Geraldine, 1005694",
//             "Tamanna Kaur Bajaj, 1003090",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_13: {
//           // team_name: "Smart Health",
//           team_name: "",

//           zoom_link: "",
//           project_title: "Society meets Anxiety",
//           project_description: `With the amount of information that is available on the internet, are we able to utilise them and use it to help others. According to recent research, an average person checks their phone 63 times a day and spends 2 hours and 51 minutes per day on their mobile device (Milijic, 2021). This is a huge opportunity space we can leverage on to dissipate the information to our target group.With the pandemic, the mental health rate in Singapore has increased as a recent survey revealed that 1 in 3 Singaporeans saw a decline in their mental health. (Goh, 2021)

//             Society meets Anxiety is a screensaver application that will display information on our smartphones or internet browsers that will educate users on the basic knowledge on how to handle and manage anxiety attacks if they or someone they know or sees experiences them. The same application will also provide reminders for them to check in on their friends. Through the increase of the knowledge of anxiety disorders, users will be more aware of the reasons which will help to destigmatise people with anxiety disorders.

//             We aim to target youths aged between 17 and 25 and arm them with the knowledge of proper handling of individuals suffering from anxiety attacks. Within 2 weeks of this campaign, we seek to increase the frequency of youths checking in on the well-being of their friends by 30% and also reduce the number of youths who express social stigma against victims of anxiety disorders by 30%. Within 6 months of this campaign, we aim to increase the number of youths who have knowledge of handling anxiety attacks by 20% and increase their perceived self efficacy in managing anxiety attacks by 30%.`,
//           team_members: [
//             "Mohamad Arshad, 1004387",
//             "Ivan Tandyajaya, 1004572",
//             "Chew Liu Im, 1004608",
//             "Cindy Yu, 1004658",
//             "Jun Hao, 1004295",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_14: {
//           // team_name: "Mental Well",
//           team_name: "",

//           zoom_link: "",
//           project_title: "Mental Well",
//           project_description: `Mental Well is a Telegram chat set up for youths suffering from mental disorder to easily and anonymously seek help 24/7. 1 in 5 Singaporean youths, aged 18 to 34 (Choo, 2018), experience mental health issues in their lifetime. Due to the COVID-19 pandemic, Dodson (2020) observed that on an average, more youths are suffering from mental health issues worldwide, which in turn leads to a higher suicide rate amongst youths.

//             We aim to increase youths' willingness to seek help on mental issues by engaging them using the Mental Well Telegram chat.The project objective is to engage 1000 youths aged 18 to 34 (5% of at-risk youths) who accessed Mental Well within 6 months.

//             Drawing from Roger's 1983 Protection Motivation Theory, Mental Well and its accompanying outreach efforts aims to address both threat appraisals and coping appraisals. By emphasising how susceptible youths are to mental illness, as well as the severity of not seeking help promptly, the outreach effort aims to increase vulnerability, severity and fear to motivate them to seek help.

//             As the Telegram chat is positioned as a simple, 2-step process, this would also increase self-efficacy in youths. Lastly, by appointing a spokesperson to share their experience and success stories using the chat would serve to remove the stigma of mental illness, and increase response-efficacy as well as reduce response costs for youths.`,
//           team_members: [
//             "Felicia Chan Jia Hui, 1005682",
//             "Khalishah Nadhirah Bte Abu Bakar, 1000668",
//             "Lee Sze Sze, 1005825",
//             "Toh Sing Yee, 1005710",
//           ],
//           healthcare_partners: [],
//         },
//       },
//       {
//         team_15: {
//           // team_name: "HealthyCampus150",
//           team_name: "",

//           zoom_link: "",
//           project_title: "HealthyCampus150",
//           project_description: `It's hard enough to find the motivation to exercise when bogged down by projects and coursework. With a shift to online classes and restrictions on club activities, there has been a reduction of physical activity levels of university students during the COVID-19 pandemic.

//             The project's objective is to increase the number of university students fulfilling the recommended 150 minutes of moderate-to-vigorous physical activity a week. Our mobile application is designed to get users to up their active minutes by utilizing intrinsic motivational framework.

//             Individual users can choose to challenge themselves with features like double-or-nothing streaks. We also include team leaderboards as the campus community is central to university students.`,
//           team_members: [
//             "Leong Yun Qin Melody, 1004489",
//             "Pui Jia Ying, 1004638",
//             "Hoo Yong Wei, Gerald, 1004677",
//             "Gargi Pandkar, 1004680",
//           ],
//           healthcare_partners: [],
//         },
//       },
//     ],
//   },
// ];

// Returns list of all the teams
export function getAllTeams() {
  return teamData;
}

export function getTeams(course) {

return teamData[course];
}

export function getTeam(course, teamName) {

  return teamData[course].find((team) => team.team_name == teamName);
}
