// Course Icons
import aihealthcareicon from "../assets/aihealthcare/aihealthcare_icon.svg";
import healthcarepdicon from "../assets/healthcarepd/healthcarepd_icon.svg";
import globalhticon from "../assets/globalht/globalht_icon.svg";

// Faculty Photos
// Global Healthcare
import julia from "../assets/globalht/julia.jpg";
import kxj from "../assets/globalht/khoo_xiaojuan.jpg";

// AI Healthcare
import ngaiman from "../assets/aihealthcare/ngaiman_cropped.jpg";
import kxj_cropped from "../assets/aihealthcare/kxj_cropped.jpg";

// Healthcare Product Design: Huang Shaoying
import subbu from "../assets/healthcarepd/subbu.jpg";
import huang_shaoying from "../assets/healthcarepd/huang_shaoying.jpg";

let courses = [
  {
    name: "01.101 Global Healthcare Technologies (SMT)",
    partners: "Partner: Assisi Hospice",
    icon: globalhticon,
    path: "globalht",
    desc:
      "This course offered by SMT provides a multi-disciplinary approach to global health technology design using real world projects and partners. The course explores the current state of global health challenges (with special focus on Singapore and her neighbours), and teaches students how to design medical technologies (e.g. products, systems) that address these problems. The same technology that is applicable in developing countries will be tested in Singapore as a pilot run, before being introduced to the neighboring countries. In their respective project groups, students will brainstorm and define current elderly issues pertaining to community hospitals in Singapore. Guest lectures, panel discussions and organized field trip will allow student to have a first-hand experience to global health issues and help students come up with better solutions. The Biodesign Innovation process will be taught and applied in the student projects.",
    course_page_url: "https://smt.sutd.edu.sg/education/undergraduate/courses/01101-global-health-technologies",
    faculty_info: [
      {
        img: kxj_cropped,
        name: "Dr Xiaojuan Khoo",
        website: "https://smt.sutd.edu.sg/people/faculty/xiaojuan-khoo",
        email: "xiaojuan_khoo@sutd.edu.sg",
        faculty_page: "https://smt.sutd.edu.sg/",
        faculty: "SMT",
      },

      {
        img: julia,
        name: "Dr Yajuan (Julia) Zhu",
        website: "https://smt.sutd.edu.sg/people/faculty/yajuan-julia-zhu",
        email: "yajuan_zhu@sutd.edu.sg",
        faculty_page: "https://smt.sutd.edu.sg/",
        faculty: "SMT",
      },
    ],
    // PLACEHOLDER TEAMS
    teams: ["IV-Safe", "Assis(tem)", "Can eat?", "Stopflo", ],
  },
  {
    name: "01.116 AI for Healthcare (ISTD/SMT)",
    partners: "Partners:CGH, TTSH, IHIS",
    icon: aihealthcareicon,
    path: "aihealthcare",
    desc: "This course co-offered by ISTD and SMT introduces the concept of artificial intelligence through machine learning and deep learning algorithms. In evidence‐based medicine, it is important to gather useful insights from both structured (e.g. data collected by machines) and unstructured data (e.g. notes from clinicians’ observations). The course highlights the use of AI for medical diagnostics, doctor consultation, personalized disease treatments, electronic health records, drug discovery and others. This poses both opportunities and challenges to gather buy‐in from various stakeholders, namely patients, doctors, researchers, and telehealth. In their various group projects, students will get to work on real‐life healthcare problems faced in hospitals and use AI to solve them.",
    course_page_url: "https://istd.sutd.edu.sg/undergraduate/courses/01116-ai-for-healthcare",
    faculty_info: [
      {
        name: "Associate Professor Ngai-Man (Man) Cheung",
        img: ngaiman,
        website: "https://istd.sutd.edu.sg/people/faculty/ngai-man-man-cheung",
        email: "ngaiman_cheung@sutd.edu.sg",
        faculty_page: "https://istd.sutd.edu.sg/",
        faculty: "ISTD",
      },
      {
        img: kxj_cropped,
        name: "Dr Xiaojuan Khoo",
        website: "https://smt.sutd.edu.sg/people/faculty/xiaojuan-khoo",
        email: "xiaojuan_khoo@sutd.edu.sg",
        faculty_page: "https://smt.sutd.edu.sg/",
        faculty: "SMT",
      },
    ],
    teams: ['Hipnosis', 'SafeEar', 'Meowringoplasty', 'APTBERT']

  },
  {
    name: "30.123 Healthcare Product Design (EPD)",
    partners: "Partners:CGH, KKH",
    icon: healthcarepdicon,
    path: "healthcarepd",
    desc: "This project-based design course offered by EPD aims to teach the students in developing fundamentals of the design aspects of biomedical and healthcare devices and diagnostics. The course has been designed to educate students to solve real-life clinical problems using their engineering knowledge by designing and developing biomedical products that can be ranging from devices, in-vitro diagnostic kits, and instruments. \n At the end of the course, students would have gained the knowledge needed to design and develop healthcare products, acquired an understanding of fundamental principles of design process and aspects of biomedical devices and diagnostics, and learned how to execute a successful healthcare product design and development by avoiding common quality and regulatory issues.",
    course_page_url: "https://epd.sutd.edu.sg/undergraduate-courses/30123-healthcare-product-design/",
    faculty_info: [
      {
        name: "Assistant Professor Subburaj Karupppasamy",
        img: subbu,
        website: "https://epd.sutd.edu.sg/people/faculty/subburaj-karupppasamy",
        email: "subburaj@sutd.edu.sg",
        faculty_page: "https://epd.sutd.edu.sg/",
        faculty: "EPD",
      },
      {
        name: "Associate Professor Huang Shaoying",
        img: huang_shaoying,
        website: "https://epd.sutd.edu.sg/people/faculty/huang-shaoying",
        email: "huangshaoying@sutd.edu.sg",
        faculty_page: "https://epd.sutd.edu.sg/",
        faculty: "EPD",
      },
    ],
    teams: [
    //   // 'G02 - Pharyngeal Imaging Forceps',
    'Cement Injector Device',
    // 'Patient-Specific Cutting Jig',
    'Proning device for Patient Transfer',
    'Ambulatory Uroflowmetry Device',
    'NG Tube Placement',
    'Patient Positioning Device']
  },
];

export function getCourses() {
  return courses;
}

export function getCourse(id) {
  return courses.find((course) => course.path === id);
}
