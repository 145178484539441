import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Router, Routes, BrowserRouter, Route } from "react-router-dom";
import { CoursePage, GlobalHTPage } from "./components/CoursePage";
import { ScrollToTop } from "./utils/ScrollToTop";
import MyNavbar from "./components/MyNavbar";
import HomePage from "./pages/HomePage";
import { SideNav } from "./components/SideNav";
import { TeamPage } from "./components/TeamPage";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* <MyNavbar/> */}
          <Route path="/" element={<MyNavbar />}>
            <Route index element={<HomePage />} />

            <Route path="courses" element={<SideNav />}>
              <Route path=":courseId" element={<CoursePage />}>
                 <Route path="teams">
                  <Route path=":teamId" element={<TeamPage />} />
                  <Route
                    path="*"
                    element={
                      <main style={{ padding: "1rem" }}>
                        <p>There's nothing here!</p>
                      </main>
                    }
                  />
                </Route> 
              </Route>
            </Route>

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                <h3>Oops.</h3>
                  <p>There's nothing here right now! Check back again later :)</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
