import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import "./styles/TeamCard.css" 
import { useNavigate } from "react-router-dom";
// import { Document, Page, pdfjs } from "react-pdf";
// import { pdf } from "@react-pdf/renderer";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TeamCard = ({
  team_name,
  zoom_link,
  project_title,
  project_description,
  team_members,
  healthcare_partners,
  team_number,
  course,
  passcode
}) => {

const navigate = useNavigate();
  const [image, setImage] = useState();
  const [pdf, setPdf] = useState();

  const loadPoster = () => {
    import(`../assets/${course}/teams/${team_number}/poster.png`)
      // import(`../assets/${course}/teams/${team_number}/poster.pdf`)

      .then((image) => {
        // console.log(image);
        setImage(image.default);
      })
      .catch((e) => {
        console.log(e);
        
      });
  };

  const loadPdf = () => {
    import(`../assets/${course}/teams/${team_number}/poster.pdf`)
      .then((pdf) => {
        // console.log(pdf);
        setPdf(pdf.default);
      })
      .catch((e) => {
        console.log(e);
        
      });
  };

  const goToProject = () => {
  navigate(`teams/${team_name}`)
  
    // history.push({
    //   pathname: `/project/${project_title}`,
    //   state: {
    //     team_name,
    //     zoom_link,
    //     project_title,
    //     project_description,
    //     team_members,
    //     healthcare_partners,
    //     course,
    //     poster: image,
    //     pdf: pdf,
    //     passcode: passcode
    //   },
    // });
  };

  useEffect(() => {
    loadPoster();
    loadPdf();
  });
  return (
    <Card className="shadow mb-5 bg-body rounded mt-3 grow w-50 text-center">
      {team_name && <Card.Body style={{backgroundColor: "#b4dee0", fontWeight: "bolder", cursor:"pointer"}} onClick={() => goToProject()}>{team_name == "Can eat" ? team_name + "?" : team_name}</Card.Body>} 
      {/* <Card.Img
        style={{ cursor: "pointer" }}
        onClick={() => goToProject()}
        src={image}
      ></Card.Img> */}

        {/* <Card.Title className=" mt-auto">{project_title} test</Card.Title> */}
      {/* <Card.Body className="d-flex flex-column">
        {zoom_link && (
          <>
          <Button
            onClick={() => window.open(zoom_link, "_blank")}
            className=" mt-auto"
            variant="primary"
          >
            Join Live Zoom Presentation
          </Button>
          <span className="mt-2"><b>Passcode: </b> {passcode}</span>
          </>
        )}
      </Card.Body> */}
      {/* <p>Team Number: {team_number}</p> */}
    </Card>
  );
};

export default TeamCard;
