import React from "react";
import "./styles/CustomCard.css";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
// test

export default function CustomCard({ title, info, icon, to }) {
  const navigate = useNavigate();
  return (
    <div className="card-container" onClick={() => navigate(to)}>
      <Row >
        <Col className="mt-3 mb-3" lg={4} md={4} sm={4} xs={4}>
          <div className="card-img-holder">
            <img className="" src={icon} height={50} />
          </div>
        </Col>
        <Col className="mt-3">
          <div className="course-card-info ">
            <h1>{title}</h1>
            {/* TODO: ADD BACK THE PARTNERS */}
            {/* <p className="desc">{info}</p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}
