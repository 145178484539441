import React, { useRef, useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router";

import gsap from "gsap";
import { Row, Col, Container , Button} from "react-bootstrap";
import { Outlet, useParams } from "react-router";
import { SideNav } from "./SideNav";
import { getCourse } from "../assets/coursesData";
import "./styles/CoursePage.css";
// import helolo from "../assets/globalht/"
import julia from "../assets/globalht/julia.jpg";
import { FacultyCard } from "./FacultyCard";
import { NavLink } from "react-router-dom";
import headerImg from "../assets/header.jpeg";
import TeamGrid from "./TeamGrid";

export const CoursePage = () => {
  const params = useParams();
  const { pathname } = useLocation();

  const imgRef = useRef();
  let location = useLocation();
  // useEffect(() => {
  //   // const images = [imgRef.current.children, imgRef.current.children.children]
  //   console.log(imgRef.current.childNodes)
  //   const imgFadeIn = gsap.to(imgRef.current.childNodes, {
  //     opacity: 1,
  //     duration: 1,
  //   });
  //   return () => {};
  // }, [pathname]);
  let course = getCourse(params.courseId);
  return (
    <>
      {!params.teamId && (
        <Container className="ml-3 course-content">
          

          <img
            className="header-img d-block m-auto"
            style={{ width: "90%", height: "auto" }}
            // src="https://healthitanalytics.com/images/site/article_headers/_normal/2019-02-27-AI-content-hub.jpg"
            // src="https://about.sharecare.com/wp-content/uploads/2020/10/GettyImages-1164501571.jpg"
            src={headerImg}
          />
          <div className="course-info ">
            <Row className="course-info-text d-flex">
              <Col className="mb-3" sm={12}>
                <h2 className="course-header mr-3">{course.name}</h2>
              </Col>
              <Col>

            

                <p className="course-desc">{course.desc}</p>
                <p>
                  Click{" "}
                  <a target="_blank" href={course.course_page_url}>
                    here
                  </a>{" "}
                  to read more.{" "}
                </p>
                {/* <p className="course-desc">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Cumque architecto dolor provident dolorum reprehenderit quod
                  veritatis! Quia ducimus blanditiis earum fugit? Dolor,
                  quisquam nobis vitae, impedit tempora sequi consequuntur qui
                  similique beatae voluptates assumenda distinctio ipsum
                  nesciunt maiores sit fuga soluta atque, rem enim dolorum iusto
                  necessitatibus debitis delectus illum.
                </p> */}
              </Col>
              <div
              className="mb-3"
              >
              <Button
              className="mb-1"
            href={"https://sutd-edu.zoom.us/j/94538413956"}
            target="_blank"
          >
            Watch Exhibition
          </Button>
          <div> Passcode: SutdIHD@20</div>
          </div>
            </Row>

            <Row ref={imgRef} className="faculty-photos m-sm-3">
              {course.faculty_info.length == 2 ? (
                <>
                  {" "}
                  <Col>
                    <FacultyCard faculty_info={course.faculty_info[0]} />
                  </Col>
                  <Col>
                    <FacultyCard faculty_info={course.faculty_info[1]} />
                  </Col>
                </>
              ) : (
                <>
                  {" "}
                  <FacultyCard faculty_info={course.faculty_info[0]} />
                </>
              )}
            </Row>
          </div>
          <TeamGrid  course={course} />
        </Container>
      )}

      

      <div className="ml-3 course-content">
                



        <Outlet />
      </div>
    </>
  );
};
