import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import { getTeam } from "../assets/teamData";
import nl2br from "react-newline-to-break";
import { Fireworks } from "fireworks/lib/react";
import "./styles/TeamPage.css";
import headerImg from "../assets/header.jpeg";

export const TeamPage = () => {
  const params = useParams();
  // console.log(params)
  const { courseId, teamId } = params;
  const teamData = getTeam(courseId, teamId);
  const [showFirework, setShowFirework] = useState();

  let fxProps = {
    count: 2,
    // interval: 1000,
    colors: ["#cc3333", "#6DEF50", "#3461eb"],
    // x: 200,
    calc: (props, i) => ({
      ...props,

      x: window.innerWidth - 200,
      y: window.innerHeight - 450,
      // x: (i + 1) * (window.innerWidth / 3) - (i + 1) * 100,
      // y: 200 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0)
    }),
  };

  const [pdf, setPdf] = useState();
  const [img, setImg] = useState();

  const loadPdf = () => {
    // import(`../assets/globalht/teams/ARTability/poster.pdf`)
    console.log(teamId);
    let tempTeamId;
    if (teamId == "Can eat") {
      tempTeamId = "caneat";
    } else {
      tempTeamId = teamId;
    }

    // tempTeamId = tempTeamId.replace(" ", "-")
    console.log(tempTeamId);
    import(`../assets/${courseId}/teams/${tempTeamId}/poster.pdf`)
      // import(`../assets/${courseId}/teams/caneat/poster.pdf`)
      .then((pdf) => {
        // console.log(pdf);
        setPdf(pdf.default);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const loadImg = () => {
    // import(`../assets/globalht/teams/ARTability/poster.pdf`)
    import(`../assets/${courseId}/teams/${teamId}/poster.png`)
      .then((img) => {
        // console.log(img);
        setImg(img.default);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    loadPdf();
    loadImg();
    return () => {
      setPdf("");
      setImg("");
    };
  }, [params]);
  return (
    <Container className="ml-5">
      {showFirework && <Fireworks {...fxProps} />}
      <img
        className="header-img d-block m-auto"
        style={{ width: "90%", height: "auto" }}
        // src="https://healthitanalytics.com/images/site/article_headers/_normal/2019-02-27-AI-content-hub.jpg"
        // src="https://about.sharecare.com/wp-content/uploads/2020/10/GettyImages-1164501571.jpg"
        src={headerImg}
      />
      <br />
      <h1>{teamId == "Can eat" ? teamId + "?" : teamId}</h1>
      {teamData && (
        <div>
          <Row className="mt-5 team-info">
            <Col className="team-header" lg={8} md={6} sm={12}>
              <h2>{teamData.project_title}</h2>

              <div className="team-description">
                {nl2br(teamData.project_description)}
              </div>
            </Col>

            <Col className="mt-3 team-members">
              <div>
                <h4>Team Members</h4>

                <ul>
                  {teamData.team_members.map((member) =>
                    member == "Claudia Koh Wei Ting, 1004679" ? (
                      <li
                        onClick={(e) => {
                          setShowFirework(true);
                          setTimeout(() => {
                            setShowFirework(false)
                          }, 1000)
                        }}
                      >
                        {member}
                      </li>
                    ) : (
                      <li>{member}</li>
                    )
                  )}
                </ul>
              </div>
            </Col>
          </Row>

          <Row className="mt-3 team-info">
            <Col className="zoom-button" lg={8} md={6} sm={12}>
              {/* TODO: ADD ZOOM LINKS HERE */}
              <Button href={teamData.zoom_link} target="_blank">
                Zoom Link
              </Button>
              <div className="mt-2">
                Passcode: <strong>{teamData.passcode}</strong>
              </div>
            </Col>

            <Col className=" healthcare-partners ">
              <div>
                {teamData.healthcare_partners && <h4>Healthcare Partners</h4>}
                <ul>
                  {teamData.healthcare_partners &&
                    teamData.healthcare_partners.map((partner) => (
                      <li> {partner}</li>
                    ))}
                </ul>
              </div>
            </Col>
          </Row>

          <div className="mt-5 d-none d-lg-block row-container">
            {pdf && (
              <iframe
                width="100%"
                height="1000"
                frameBorder="0"
                // sandbox="allow-popups-to-escape-sandbox"
                // src={`${pdf}#zoom=50`}
                src={`${pdf}#view=FitH`}
              />
            )}
          </div>

          <Container className="team-poster-img d-lg-none">
            <Image className="text-center" src={`${img}`} />
          </Container>
        </div>
      )}
    </Container>
  );
};
