import React, { useEffect, useState, useRef } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { getCourses } from "../assets/coursesData";
import { Accordion, Card } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillRightSquare } from "react-icons/ai";
import useBreakpoint from "../utils/useBreakpoint";

import "./styles/SideNav.css";
export const SideNav = () => {
  const point = useBreakpoint();
  const navNode = useRef();

  let courses = getCourses();
  const [shown, setShown] = useState(true);

  let activeStyle = {
    color: "darkblue",
    fontWeight: "bold",
  };

  useEffect(() => {
    // console.log("Point: ", point);
    if (point === "sm" || point === "xs") {
      setShown(false);
    } else {
      setShown(true);
    }
  }, [point]);

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (navNode.current.contains(e.target)) {
      console.log("contains");
      return;
    } else {
      if (shown && point !== "lg") {

        setShown(false);
      }
    }
  };
  const toggleNavBar = () => {
    console.log("toggling ")
    setShown((prevState) => !prevState);
  };

  const closeSideNav = () => {
    if (point === "sm" || point === "xs") {
      setShown(false);
    }
  };

  return (
    <div className="main-wrapper">
      {!shown && (
        <span className="openBtn" onClick={() => toggleNavBar()}>
          <AiFillRightSquare />
        </span>
      )}
      <div className={"sidenav sidenav" + (shown ? "-open" : "-closed")}>
        <span className="closeBtn d-sm-none d-lg-block" onClick={() => toggleNavBar()}>
          {shown && <IoCloseSharp />}
        </span>
        <Accordion ref={navNode} className="mt-5" defaultActiveKey="0">
          {courses.map((course, idx) => (
            <Accordion.Item eventKey={idx}>
              <Accordion.Header>
                <NavLink
                  // style={{ display: "block", margin: "1rem 0" }}
                  to={`/courses/${course.path}`}
                  key={idx}
                  // className={activeClassName="active"}
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  {course.name}
                </NavLink>
              </Accordion.Header>

              <Accordion.Body>
                {course.teams &&
                  course.teams.map((team, idx) => (
                    <NavLink
                      onClick={() => {
                        closeSideNav();
                      }}
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      className="team-link"
                      to={`${course.path}/teams/${team}`}
                    >
                      {team}
                    </NavLink>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}

          {/* <Accordion.Item eventKey="0">
            <Accordion.Header>Big Link</Accordion.Header>
            <Accordion.Body>Small Links</Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
      </div>
      <Outlet />
    </div>
  );
};
