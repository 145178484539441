import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import TestBox from "./test/TestBox";
import HomePage from "./pages/HomePage";
import { Outlet, Router, Routes, BrowserRouter, Route } from "react-router-dom";
import MyNavbar from "./components/MyNavbar";
import { Container } from "react-bootstrap";
function App() {
  return (
    <Container  className="App">
      <MyNavbar />
      <HomePage />
      <Outlet />
    </Container>
  );
}

export default App;
