import React, { useEffect } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { FaSchool } from "react-icons/fa";
import "./styles/FacultyCard.css";
export const FacultyCard = ({ faculty_info }) => {
  const { email, faculty, faculty_page, img, name, website } = faculty_info;
  /*
email: "yajuan_zhu@sutd.edu.sg"
​
faculty: "SMT"
​
faculty_page: "https://smt.sutd.edu.sg/"
​
img: "/static/media/julia.54edd94c.jpg"
​
name: "Dr Xiaojuan Khoo"
​
website: "https://smt.sutd.edu.sg/people/faculty/yajuan-julia-zhu"
    */

  return (
    <div className="faculty-card" style={{ textAlign: "center" }}>
      <img src={img} alt={name} className="faculty-photo" />
      <br />
      <span>
        <strong>
          <a target="_blank" href={website}>
            {" "}
            {name}
          </a>
        </strong>
      </span>
      <br />
      <span>
      <strong>
        <AiOutlineMail /> <a  target="_blank" href={
        `mailto:${email}`}>{email}</a>{" "}
        </strong>
      </span>
      <br />
      <span>
        
        <a target="_blank" href={faculty_page}>
        <FaSchool />{" "}
          {faculty}
        </a>
      </span>
    </div>
  );
};
