import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router";
export const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    // console.log(pathname);
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  return children;
};
