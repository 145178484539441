import React from "react";
import { Link, Outlet } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import "./styles/MyNavbar.css";
const MyNavbar = () => {
  return (
    <div>
      <Navbar className="p-2" expand="lg">
        <Container fluid >
          <Navbar.Brand href="/">Home</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
          
              <NavDropdown title="Programme" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="courses/globalht">
                  01.101 Global Healthcare Technologies
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item as={Link} to="courses/aihealthcare">
                  01.116 AI for Healthcare
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="courses/healthcarepd">
                  30.123 Healthcare Production Design
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </div>
  );
};

export default MyNavbar;
