import React, { useLayoutEffect, useRef, useState } from "react";
import "./home.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CustomCard from "../components/CustomCard";
import aihealthcareicon from "../assets/aihealthcare/aihealthcare_icon.svg";
import headerImg from "../assets/header.jpeg"
import zoomImg from "../assets/cyborg-school.png";
import { getCourses } from "../assets/coursesData";
import { NavLink } from "react-router-dom";

import { getCourse } from "../assets/coursesData";
export default function HomePage() {
  gsap.registerPlugin(ScrollTrigger);
  let courses = getCourses();

  const line1 = useRef();
  const line2 = useRef();
  const headerImgRef = useRef();
  const aboutRef = useRef();
  const virtualPosterRef = useRef();
  const coursesRef = useRef();

  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width: 650px)": function() {
        // const aboutAnim = gsap.fromTo(
        //   aboutRef.current.children,
        //   { opacity: 0 },
        //   {
        //     scrollTrigger: {
        //       // markers: true,
        //       trigger: aboutRef.current,
        //       start: "center 50%",
        //       toggleActions: "play reset play none",
        //     },

        //     opacity: 1,
        //     ease: "power2.out",
        //     duration: 1.5,
        //   }
        // );

        const aboutAnim = gsap.from(aboutRef.current.children, {
            // y: 100,
            x:-100,
            markers: true,
            ease: "power0",
            // stagger: {
            //   ease: "power2.in",
            // },
            delay: 2.5,
            opacity: 0,
          });

        const virtualPosterAnim = gsap.fromTo(
          virtualPosterRef.current.children,
          { opacity: 0 },
          {
            scrollTrigger: {
              // markers: true,
              trigger: virtualPosterRef.current,
              start: "center 80%",
              toggleActions: "play reset play none",
            },

            opacity: 1,
            ease: "power2.out",
            duration: 1.5,
          }
        );

        const coursesAnim = gsap.fromTo(
          coursesRef.current.children,
          { opacity: 0 },
          {
            scrollTrigger: {
              // markers: true,
              trigger: coursesRef.current,
              start: "center 90%",
              toggleActions: "play reset play none",
            },

            opacity: 1,
            ease: "power2.out",
            duration: 1.5,
          }
        );
      },
      "(max-width: 599px)": function() {},
    });
    // H1 Intro Text Animation
    // const lines = [line1.current.children, line2.current.children];

    // const contentAnim = gsap.from(lines, {
    //   y: 50,
    //   stagger: {
    //     each: 0.3,
    //     ease: "power2.in",
    //   },
    //   delay: 2,
    // });

    // Image Animation
    headerImgRef.current = gsap
      .timeline()
      .from(headerImgRef.current, {
        y: -800,
        duration: 2.8,
        ease: "Power3.easeOut",
        opacity: 0,
      })
      .from(
        headerImgRef.current.children,
        {
          scale: 1.2,
        },
        "0.10"
      );

    return () => {
      // contentAnim.kill();
      // aboutAnim.kill();
    };
  }, []);
  return (
    <div>
      <Container className="container-home">
        <div className="hero">
          <section className="home-header" >
          {/* style={{marginBottom: "1px", marginTop: "20px", padding: "1px"}} */}
            {/* <Row xs={1} md={2}> */}
              {/* <Col>
                <div className="intro-text">
                  <h1>
                    <div className="intro-text-content">
                      <div ref={line1} className="intro-text-content-line">
                        <div className="intro-text-content-line-inner">
                          Innovations in
                        </div>
                      </div>

                      <div ref={line2} className="intro-text-content-line">
                        <div className="intro-text-content-line-inner">
                          Healthcare Design
                        </div>
                      </div>
                    </div>
                  </h1>
                  <div>
                    <p>
                     
                      Showcasing our students’ healthcare efforts across the
                      various pillars/clusters in our various Healthcare courses
                    </p>
                  </div>
                </div>
              </Col> */}
              {/* className="col-lg-6 col-md-12 center-img col-sm-auto" */}
              {/* <Col> */}
              <img
              ref={headerImgRef}
                    className="header-img d-block m-auto"
                    // height="300px"
                    // src="https://healthitanalytics.com/images/site/article_headers/_normal/2019-02-27-AI-content-hub.jpg"
                    // src="https://about.sharecare.com/wp-content/uploads/2020/10/GettyImages-1164501571.jpg"
                    src={headerImg}
                  />
                <div className="header-img-inner" >
                  
                </div>
              {/* </Col> */}
            {/* </Row> */}
          </section>
        </div>

        <section>
          <div className="home-about">
            <div className="home-about-inner">
              <div ref={aboutRef} className="home-about-line">
                <h2>
                  <div className="about-header">
                    Welcome to IHD 2022 Virtual Exhibition
                  </div>
                </h2>
                <p>
                  <p>
                    Innovations in Healthcare Design (IHD) 2022 is an annual
                    joint showcase of our students’ healthcare efforts across
                    the various pillars/clusters in the below Healthcare
                    courses, namely:
                  </p>
                  <ul>
                    <li> 01.101 Global Healthcare Technologies (SMT)</li>
                    <li> 01.116 AI for Healthcare (ISTD / SMT)</li>
                    <li> 30.123 Healthcare Product Design (EPD)</li>
                  </ul>
                </p>
                <p>
                  Over the course of a term, students have teamed up with
                  practicing clinicians and professionals from various
                  healthcare partners across Singapore to deliver innovative
                  healthcare design solutions to current unmet clinical needs.
                  They have gone through an extensive design innovation process
                  to identify and validate unmet clinical needs, develop
                  solution strategies and design concepts, and develop working
                  prototypes of those design solutions.
                </p>
                <p>
                  Join us (virtually) to witness their design ideas and discover
                  the design process that ultimately resulted in the realization
                  of these innovative design solutions.
                </p>
                <p>
                  There will also be a mini-physical poster showcase at L1
                  Campus Centre from 19th April to 8th May 2022!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div ref={virtualPosterRef} className="live-poster-presentation">
            <Card className="shadow-lg p-3 mb-5 bg-body rounded flex-lg-row ">
              {/* <img src ={zoomImg}  style={{ width: "10px", height: "auto"}}/> */}
              <div className="m-auto" style={{ width: "60%", height: "auto"}}>
                <Card.Img src={zoomImg} style={{textAlign: "center"}} />
              </div>
              <div className="flex-grow-1 p-2">
              <Card.Body   >
                <h2 className="virtual-header">
                  <span style={{color: "red" , fontSize: 60}}>LIVE</span> Virtual Poster Presentation 2022
                  <br />
                  (20 April 2022, Wednesday (SGT)) (via Zoom)
                </h2>

                <div>
                  <strong>2pm - 5pm:</strong> LIVE Poster Presentations (via
                  Zoom)
                  <ul>
                    <li>

                      <strong>2:00 – 2.15:</strong>{" "}
                   
                      Introduction
                    </li>
                    <li>
                      <strong>3:30 – 5:00:</strong>{" "}
                         <NavLink to="/courses/aihealthcare#team-grid">
                        {" "}
                        AI for Healthcare (4 teams){" "}
                      </NavLink>{" "}
                      ,
                      <NavLink to="/courses/globalht">
                        Global Health Technologies (4 teams){" "}
                      </NavLink>
                      and{" "}
                      <NavLink to="/courses/healthcarepd">
                        Healthcare Product Design (5 teams){" "}
                      </NavLink>
                    </li>
                  </ul>
                  <i>
                    Note: Please click in to the respective course page to
                    access the LIVE Virtual Poster Presentation
                  </i>
                </div>
                <div className="w-100"></div>
              </Card.Body>
              </div>
            </Card>
          </div>
        </section>

        <section>
          <div className="home-course-list">
            <div ref={coursesRef} className="home-courses-line">
              <h2 className="courses-header">The Healthcare Courses</h2>

              <Row xs={1} md={1} lg={3}>
                {courses.map((course, idx) => (
                  <Col className="mb-3 mr-2 ml-2">
                    <CustomCard
                      title={course.name}
                      info={course.partners}
                      icon={course.icon}
                      to={`/courses/${course.path}`}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
}
