import React, { useReducer, useEffect, useState } from "react";
import { Row, Col, Container, Alert } from "react-bootstrap";
import { getTeams } from "../assets/teamData";

import TeamCard from "./TeamCard";

const TeamGrid = ({ course }) => {

  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    let shortCourseName;
    if (course.name == "01.101 Global Healthcare Technologies (SMT)") {
      shortCourseName = 'globalht'
    } else if (course.name == "01.116 AI for Healthcare (ISTD/SMT)") {
      shortCourseName = "aihealthcare"
    } else {
      shortCourseName = "healthcarepd"
    }
    setTeamList(getTeams(shortCourseName))
    console.log(teamList)
  }, [course])


  return (
    <Container id="team-grid" className="mt-4 mb-3">
    
      <h2>
        <b>Project Showcase</b>
      </h2>

      <p>
        Click on the team names below to read about their project!
      </p>
      <Row>
        {teamList ?
          teamList.map((team, i) => {
            const team_number = i + 1;
            const index = `team_${team_number}`;

 {/* zoom_link,
              project_title,
              project_description,
              team_members,
              healthcare_partners,
              passcode, */}

            const {
              team_name
             
            } = team

            return (
              <Col lg={4} className="d-flex align-items-stretch">

                <TeamCard
                  key={index}
                  team_name={team_name}
                  // zoom_link={zoom_link}
                  // project_title={project_title}
                  // project_description={project_description}
                  // team_members={team_members}
                  // healthcare_partners={healthcare_partners}
                  // course={course}
                  // passcode={passcode}
                /> 
              </Col>
            );
          }) : <div><i>There are currently no teams for this course.</i></div>}
      </Row> 
    </Container>
  );
};

export default TeamGrid;
